import React from "react";
// import { Navigation } from "swiper";
// import { Swiper, SwiperSlide } from "swiper/react";
import PageHeader from "../../components/PageHeder";
import Paragraph from "../../components/Paragraph";
import TeamCard from "../../components/TeamElements/TeamCard";
import ServiceContainer01 from "../../containers/ServiceContainer";

const TeamContainer = () => {
  const TeamData = [
    {
      id: "1",
      img: "images/team/1.jpg",
      name: "杨金荣",
      title: "研究所创始人",
      description:
        "江苏南京人，出生于1950年2月5日。1979年11月考入江苏省工艺品进出口公司，负责红木家具设计和进出口业务，不到三年把江苏红木出口额从0发展成为全国第一，是中国红木行业发展的领军人物。从事红木文化艺术 事业40余年来，在新产品设计、新材种开发、新设备引进以及新工艺推广等诸多方面做了大量的工作。率先起草并实施 全国第一部红木制品质量标准和第一部红木制品工艺标准，填补了行业空白。20世纪80年代，率先引进木工机械、木工设备和其他辅助材料， 为红木产业工艺改进和效率的提高奠定了基础。开辟了到产地购买木材的渠道，率先用人民币在瑞丽购买红木，为后来国内红木产业的嶇起提供了条件。创新开发和改 良了面边压条一体化、绷藤面坐板、乱冰格对料上挠度等多项传统木作技艺，为中国红木产业发展、加工技艺和效率的 提高做出巨大贡献。1999年，杨金荣担任江苏工美红木文化艺术研究所创始所长，创造性的提出了《红木文化艺术载体论》《三大属性论》《红木制品价值论》等一系列重要的红木理论和概念，出版了近30万字的《红木文化谈数》，在国内外知名的报 刊杂志和学术期刊上发表了上百篇专业文章，建立了自己独特的红木文化艺术理论体系，为中国红木从家具品类转向文 化艺术品类做出了巨大贡献。受聘为国际木文化学会特聘专家、副会长，长期受邀在国际交流中演讲和翻译，是国际社 会了解中国红木产业的重要桥梁纽带。杨金荣大师，是近代红木行业中有着丰富经验、高超手工艺技艺和学术造诡的专家，既能动手又能动脑还能动口，被誉为中国近代红木文化艺术奠基人、红木行业泰斗和国宝级专家。",
    },
    {
      id: "2",
      img: "images/team/2.jpg",
      name: "章正一",
      title: "现研究所所长",
      description:
        "章正一师从我国红木文化艺术大师，红木文化艺术奠基人杨金荣先生，致力于红木文化艺术理论研究和实践创作。经过不断努力，把传统元素和现代审美，手工艺和科技融合一体，通过智能设备设计和研发，工艺流程的设计和实践，在师父杨金荣40多年的实践和总结 的基础上，建立了一套完整的造美物的木作体系。早年留学美国，主攻计算机科学和人工智能，喜欢西式木作，在精通西式木作技艺的基础上，研究实木造物的天然之美。是国内马洛夫摇椅社群发起人，马洛夫摇椅公开课发起人、首席讲师。结合东西方木作技艺，融合了现代审美和传统元素，把现代科技（计算机科学、人工智能、物联网、互联网、机械、电气工程等）和传统手工艺结合，最大限度的保留和发挥人的价值，并有效利用机械的精准和高效，在造型、选材和工艺上取得了重大的突 破，建立了一套独特的现代手工艺体系。 有着精浪的木作技艺基础，人生阅历，审美和价值观的融合，并在师父杨金荣毕生积累的基础上，建立了一套造美物的体系和人文艺术空间的美学理论，在实践中不断的创新和践行。",
    },
  ];
  return (
    <section className="team in-team">
      <div className="container-sm">
        <div className="section-header medium">
          {/* <PageHeader className="heading text-red-title mb-5" title="简介" /> */}
          <div className="section-header text-center has_line"></div>
          <h3 className="text-white">简介</h3>
          <div className="section-desc row align-items-center justify-content-center text-olive ms-0 me-0">
            <Paragraph
              text="
              江苏工美红木文化艺术研究所是由江苏省工艺美术行业协会于1999年设立的独立研究机构，由我国知名的红木专家杨金荣先生担任创始所长，众多行业专家（如:吴学忠大师、盛祖坤、杨家驹教授-红木首部国家标准起草人等等）成为研究所的研究员，为我国红木业从家具 品类转向文化艺术品类做出了巨大的贡献。研究所在1999年起草，并于2000年1月10日发布了我国第一部红木行业标准《红 木制品制作工艺质量要求DB32/T368-2000》；2000年2月24日被江苏省质量技术监督局指定为江苏省红木制品制作工艺质 量鉴定单位；2004年9月2日最高人民法院审核批准成为在册红木制品司法鉴定单位；2011年5月23日由国务院批准文化部确  定被列入第三批国家级非物质文化遗产名录扩展项目名录-家具制作技艺（精细木作技艺），成为国家级非遗保护单位。"
            />
            <Paragraph text="千百年来，江苏地区物产丰富，文人茶茎，能工巧匠辈出，其独特的人文地理环境优势，为传统家具制作工艺打下深厚扎实的基础。自明代始，硬质名贵木材开始用于雕刻和木器制作，在众多江南文人积极参与和皇家的大力倡导下，江苏各地民间的木作工艺水平在得到极大提升的同时，  逐渐朝着精微细致的方向发展，形成了一种专门施行于硬木类雕塑和木器的特殊工艺--- “精细木作工艺”。清代中期因被称做“红木”的交趾黄檀等硬木的大量使用，这种工艺逐渐被民间习惯地称为“红木工 艺”，并一直延续至今。改革开发40多年来，江苏红木出口得到飞速发展，从1983年出口全国第一至今，形成了江苏苏州、南通、常州等地区红木产业集散地，在经济发展和改革开放的浪潮中，国内红木市场迅速火热，除了江苏地区外，浙江的东阳、福建的仙游、河北 廊坊、山东淪博、广东新会、广西凭祥等地的红木产业也快速嶇起，各种大小红木家具厂如雨后春笋冒出。也正是这种飞速发 展，导致火热的红木市场鱼龙混杂，以次充好、以假乱真、木材炒作、做旧做假等等，各种急功近利的商业弊端也迅速暴露出 来，导致本来高端精致的红木文人艺术家具，跌下了神坛而成为普通甚至不入流的家具，常常看到花费巨资购买的一屋子的红 木家具，却没有一件像样的作品，除了木料被炒成天价之外，选材、工艺、造型没有一样合格。正因为如此，了解改革开放以 来江苏乃至全国红木发展的这段历史，对红木家具的实用家具、工艺家具和艺术品家具的等级档次之分，以及选材、工艺、造 型的考究层度和制作者对审美层次的涂释，是有着非常大的参考价值。" />
            <Paragraph text="中国改革开放的辉煌，也是近代红木文化艺术的复兴和辉煌。1979年3月26日国务院批准发布《以进养出试行办法》，鼓励外贸企业利用当时传统木作手工艺的极佳性价比，由国家拿出少量珍贵的外汇进口红木原材料，通过中国工艺品进出口总 公司江苏省分公司分配给省内五家定点工厂，加工成红木工艺品出口创汇。" />
            <Paragraph text="1983年开始，江苏省红木制品出口稳居全国第一，出口总金额占了全国的30%份额，货源占了全国60%。全国范围内有关红木的多项第一，均在江苏诞生，苏作精滬的技艺和江苏文人特有的审美标准，成为苏作精细木作技艺和苏式红木家具及相  关艺术品的灵魂。" />
            <Paragraph text="也正是这段辉煌的历史，把杨金荣先生从下乡知青，成长为优秀外销员、红木文化艺术的理论研究者和奠基人，从一位精细木作技艺的手工艺传承人、践行者，蜕变成国家级非遗项目的保护、传承、发展和创新专家，是我国为数不多的元老级红木 艺术家、大师级匠人。杨金荣先生从业的40余年，也是中国红木发展的40余年，可以说近代中国红木发展的这辉煌历史，也 是杨金荣、吴学忠、盛祖坤和许许多多未留下姓名的老一辈红木艺术家成长的历史。" />
          </div>
        </div>
        <div className="section-header medium">
          <PageHeader className="heading text-red-title" title="人物" />
        </div>
        <div className="section-desc row align-items-center justify-content-center text-olive">
          <div>
            {TeamData.map((data) => (
              <TeamCard key={data.id} data={data} />
            ))}
          </div>
        </div>

        <PageHeader className="heading text-red-title" title="荣誉" />
        <ServiceContainer01 />
      </div>
    </section>
  );
};

export default TeamContainer;
