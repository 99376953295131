import React from 'react';

const TestimonialSlide = () => {

    return (
        <div className="testimonial-block text-center">
            <p>“Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ulla"</p>
            <h6 className="text-olive">MAX construction agency, LA</h6>
        </div>

    );
};

export default TestimonialSlide;